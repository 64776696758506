import React from 'react';
import './Timeline.styles.scss';
import { useExperienceImages } from '../../hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Timeline = ({ events = [] }) => {
  const images = useExperienceImages();

  return (
    <div className='timeline'>
      {events.map(({ title, employer, linkToEmployer, period, technologies, imgName }, i) => {
        const img = images.find((i) => i.name === imgName) || null;
        const imgData = getImage(img);
        return (
          <div
            key={i}
            className={`timeline__event`}
          >
            <div
              className='timeline__image'
              aria-hidden='true'
            >
              {imgData && (
                <GatsbyImage
                  alt={`${employer} logo`}
                  image={imgData}
                  loading='lazy'
                />
              )}
            </div>
            <div className='timeline__details'>
              <h3 className='heading-sm'>{title}</h3>
              <div>
                {linkToEmployer ? (
                  <a
                    href={linkToEmployer}
                    className='link body-md'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label={`View ${employer} website in new tab`}
                  >
                    {employer}
                  </a>
                ) : (
                  <span className='body-md'>{employer}</span>
                )}
                <span className='body-md timeline__date'>({period})</span>
              </div>
              <ul
                className='timeline__technologies'
                aria-label='technologies'
              >
                {technologies.map(({ Icon, label }) => (
                  <li
                    key={label}
                    className='timeline__technology-icon'
                  >
                    <Icon aria-hidden='true' />
                    <div className='body-sm'>{label}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
