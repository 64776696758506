import Icons from '../../modules/icons';
const {
  ReactIcon,
  SassIcon,
  NodeJsIcon,
  MongodbIcon,
  DockerIcon,
  JavaScriptIcon,
  GatsbyIcon,
  PostgresqlIcon,
  HTMLIcon,
  CSSIcon,
  NextJSIcon,
  GitIcon,
  GitHubActionsIcon
} = Icons;

export const CATEGORIES = [
  {
    name: 'front end',
    icons: [
      { Icon: CSSIcon, label: 'CSS' },
      { Icon: HTMLIcon, label: 'HTML' },
      { Icon: SassIcon, label: 'SASS' },
      { Icon: ReactIcon, label: 'React' },
      { Icon: GatsbyIcon, label: 'Gatsby' }
    ]
  },
  {
    name: 'back end',
    icons: [
      { Icon: NodeJsIcon, label: 'Node.js' },
      { Icon: NextJSIcon, label: 'Next.js' },
      { Icon: MongodbIcon, label: 'MongoDB' },
      { Icon: PostgresqlIcon, label: 'Postgresql' }
    ]
  },
  {
    name: 'devops',
    icons: [
      { Icon: DockerIcon, label: 'Docker' },
      { Icon: GitHubActionsIcon, label: 'GitHub Actions' }
    ]
  },
  {
    name: 'programming languages',
    icons: [{ Icon: JavaScriptIcon, label: 'JavaScript' }]
  },
  {
    name: 'tools',
    icons: [{ Icon: GitIcon, label: 'Git' }]
  }
];
