import Icons from '../../modules/icons';
const {
  ReactIcon,
  SassIcon,
  GatsbyIcon,
  GitIcon,
  JiraIcon,
  GitHubActionsIcon,
  ReduxIcon,
  TailwindCSSIcon,
  DotNetIcon,
  TypeScriptIcon,
  LernaIcon,
  StorybookIcon,
  PythonIcon,
  FlaskIcon,
  AngularIcon,
  MSQLIcon,
  AngularMaterialIcon,
  TeamCityIcon,
  GoogleAnalyticsIcon,
  BootstrapIcon,
  AppiumIcon,
  CSharpIcon
} = Icons;

export const WORK_EXPERIENCES = [
  {
    title: 'Software Developer',
    employer: 'Amdocs',
    linkToEmployer: 'https://www.amdocs.com',
    period: 'Sep 2022 - Present',
    technologies: [
      { Icon: GitIcon, label: 'Git' },
      { Icon: DotNetIcon, label: '.NET' },
      { Icon: JiraIcon, label: 'Jira' },
      { Icon: SassIcon, label: 'SASS' },
      { Icon: LernaIcon, label: 'Lerna' },
      { Icon: ReactIcon, label: 'React' },
      { Icon: ReduxIcon, label: 'Redux' },
      { Icon: CSharpIcon, label: 'C-Sharp' },
      { Icon: StorybookIcon, label: 'Storybook' },
      { Icon: TypeScriptIcon, label: 'TypeScript' },
      { Icon: TailwindCSSIcon, label: 'Tailwind CSS' }
    ],
    imgName: 'amdocs'
  },
  {
    title: 'Software Engineer Intern',
    employer: 'RBC',
    linkToEmployer: 'https://www.rbcroyalbank.com/',
    period: 'Sep 2021 - Dec 2021',
    technologies: [
      { Icon: GitIcon, label: 'Git' },
      { Icon: MSQLIcon, label: 'MS SQL' },
      { Icon: FlaskIcon, label: 'Flask' },
      { Icon: PythonIcon, label: 'Python' },
      { Icon: AngularIcon, label: 'Angular' },
      { Icon: AngularMaterialIcon, label: 'Angular Material' }
    ],
    imgName: 'rbc'
  },
  {
    title: 'Software Engineer Intern',
    employer: 'DBRS Morningstar',
    linkToEmployer: 'https://dbrs.morningstar.com/',
    period: 'Sep 2020 - Dec 2020',
    technologies: [
      { Icon: GitIcon, label: 'Git' },
      { Icon: DotNetIcon, label: '.NET' },
      { Icon: JiraIcon, label: 'Jira' },
      { Icon: MSQLIcon, label: 'MS SQL' },
      { Icon: CSharpIcon, label: 'C-Sharp' },
      { Icon: AngularIcon, label: 'Angular' },
      { Icon: TeamCityIcon, label: 'TeamCity' },
      { Icon: AngularMaterialIcon, label: 'Angular Material' }
    ],
    imgName: 'dbrs'
  },
  {
    title: 'Freelance Web Developer',
    employer: 'Zeera by the Bay',
    linkToEmployer: 'https://www.zeeraindiancuisine.ca',
    period: 'Mar 2020 - Apr 2020',
    technologies: [
      { Icon: GitIcon, label: 'Git' },
      { Icon: SassIcon, label: 'SASS' },
      { Icon: GatsbyIcon, label: 'Gatsby' },
      { Icon: BootstrapIcon, label: 'Bootstrap' },
      { Icon: GitHubActionsIcon, label: 'GitHub Actions' },
      { Icon: GoogleAnalyticsIcon, label: 'Google Analytics' }
    ],
    imgName: 'zeera'
  },
  {
    title: 'QA Analyst Intern',
    employer: 'Clearbridge Mobile',
    period: 'Jan 2020 - Apr 2020',
    technologies: [
      { Icon: JiraIcon, label: 'Jira' },
      { Icon: AppiumIcon, label: 'Appium' }
    ],
    imgName: 'clearbridge'
  }
];
