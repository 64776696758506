import React, { useCallback } from 'react';
import './DetailsModal.styles.scss';
import Icons from '../../../modules/icons';
import Badge from '../../Badge/Badge.component';
import Button from '../../Button/Button.component';
import { useWindowEvent, useFocusTrap } from '../../../hooks';
import { ENTER_KEY, ESCAPE_KEY } from '../../../constants';
import { replaceSpacesWithDashes, sendGTAGEvent } from '../../../utils';

const { CloseIcon, GlobeIcon, FigmaIcon, GitHubIcon } = Icons;

const DetailsModal = ({ project, isVisible, closeModal }) => {
  const { title, techStack = [], description, demoLink, sourceLink, designLink } = project || {};
  const titleId = replaceSpacesWithDashes(`${title}-modal-title`);
  const descriptionId = replaceSpacesWithDashes(`${title}-modal-description`);

  const onEscapeKeyDown = useCallback(
    (event) => {
      if (event.key === ESCAPE_KEY) {
        closeModal();
      }
    },
    [closeModal]
  );
  useWindowEvent('keydown', onEscapeKeyDown, isVisible);
  const focusTrapRef = useFocusTrap(isVisible);

  const onEnterKeyDown = (event) => {
    if (event.key === ENTER_KEY) {
      closeModal();
    }
  };

  const onLinkClicked = (type) => {
    sendGTAGEvent(`view_project_${type}`, {
      project_name: title
    });
  };

  return (
    <div
      role='dialog'
      aria-hidden={!isVisible}
      aria-modal={isVisible ? 'true' : undefined}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      className={`details-modal__overlay ${isVisible ? 'details-modal__overlay--show' : ''}`}
      ref={focusTrapRef}
    >
      <div className={`details-modal ${isVisible ? 'details-modal--show' : ''}`}>
        <div className='details-modal__header'>
          <span
            id={titleId}
            className='details-modal__title heading-md'
          >
            {title}
          </span>
          <CloseIcon
            className='details-modal__close heading-lg'
            aria-label={`Close modal`}
            role='button'
            onClick={closeModal}
            tabIndex={0}
            onKeyDown={onEnterKeyDown}
          />
        </div>
        <ul
          className='details-modal__tech-stack body-md'
          aria-label='technologies & tools used'
        >
          {techStack.map((ts) => (
            <li key={ts}>
              <Badge>{ts}</Badge>
            </li>
          ))}
        </ul>
        <p
          id={descriptionId}
          className='details-modal__description body-lg'
        >
          {description}
        </p>
        <ul
          className='details-modal__links'
          aria-label='links'
        >
          {demoLink && (
            <li>
              <Button
                href={demoLink}
                aria-label='View project in new tab'
                className='details-modal__view-project'
                onClick={() => onLinkClicked('demo')}
              >
                <span>View project</span>
                <GlobeIcon
                  aria-hidden='true'
                  className='heading-sm'
                />
              </Button>
            </li>
          )}
          {sourceLink && (
            <li>
              <Button
                href={sourceLink}
                aria-label='View code in new tab'
                className='details-modal__view-code'
                onClick={() => onLinkClicked('source')}
              >
                <span>View code</span>
                <GitHubIcon
                  aria-hidden='true'
                  className='heading-sm'
                />
              </Button>
            </li>
          )}
          {designLink && (
            <li>
              <Button
                href={designLink}
                aria-label='View design in new tab'
                className='details-modal__view-design'
                onClick={() => onLinkClicked('design')}
              >
                <span>View design</span>
                <FigmaIcon
                  aria-hidden='true'
                  className='heading-sm'
                />
              </Button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DetailsModal;
