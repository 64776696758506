export const PROJECTS = [
  {
    title: 'Tokyo Treasures',
    category: 'web',
    previewImgName: 'tokyo_treasures',
    techStack: ['Tailwind CSS', 'Shadcn', 'Gatsby', 'Github Actions', 'Google Analytics', 'ESLint', 'TypeScript'],
    description: 'A responsive restaurant website that is optimized for performance, SEO, and accessibility',
    sourceLink: 'https://github.com/Chandra-Panta-Chhetri/tokyo-treasures',
    demoLink: 'https://tokyo-treasures.pages.dev',
    designLink: 'https://www.figma.com/design/HoPdh5o6sjGMkdP9dRhJbU/Tokyo-Treasures'
  }
];
